import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from './components/context';
import Routers from './components/Routers';
import './App.css';

const App = () => {
   return (
    <BrowserRouter>
        <Provider>
            <Routers />        
        </Provider>
    </BrowserRouter>
  );
}

export default App;
