import React, { createContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

// create context api
export const PageContext = createContext();

// provider for context api
export const Provider = withRouter((props) => {
    const [ loading, setLoading ] = useState(false);
    const [ isHomePage, setIsHomePage ] = useState(true);
    const [ toggleMenu, setToggleMenu ] = useState(false);
    const [ screenSize, setScreenSize ] = useState('desktop');

    const url = props.location.pathname;

    // set screenSize state based on window width on screenSize state change
    useEffect(() => {
        window.addEventListener('resize', () => {
            if(window.innerWidth > 736) {
                setScreenSize('desktop');
            } else if(window.innerWidth <= 736 && window.innerWidth > 480) {
                setScreenSize('tablet');
            } else {
                setScreenSize('phone');
            }
        }); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(window.innerWidth > 736) {
            setScreenSize('desktop');
        } else if(window.innerWidth <= 736 && window.innerWidth > 480) {
            setScreenSize('tablet');
        } else {
            setScreenSize('phone');
        }
    }, [screenSize])
   
    return(
        <PageContext.Provider value={{ 
                isHomePage,
                loading,
                screenSize,
                toggleMenu,
                url,
                action: {
                    setLoading,
                    setIsHomePage,
                    setScreenSize,
                    setToggleMenu
                }
            }}>
            { props.children }
        </PageContext.Provider> 
    );
})
