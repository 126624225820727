import React from 'react';
import { Spring } from 'react-spring/renderprops';
import '../App.css';

const Loading = () => {
    return(
        <Spring 
            from={{ opacity: 1 }}
            to={{ opacity: 0 }}
            config={{ delay: 1500, duration: 2000}}
        >
            { props => (
                <div style={ props }>
                    <div className='Loading'>
                        <div className="Loading-bar">
                            <div className="Loading-circle"></div>
                            <p>Welcome</p>
                        </div>
                    </div>
                   
                </div>
            )}
        </Spring>
    );
}

export default Loading;