import React, { useContext, useEffect, useState } from 'react';
import { Spring } from 'react-spring/renderprops';
import { PageContext } from './context';
import Menu from './Menu';
import Home from './Home';
import About from './About';
import Projects from './Projects';
import Resume from './Resume';
import Contact from './Contact';
import '../App.css';

const Landing = () => {
    const context = useContext(PageContext);

    const [ inactiveButton, setInactiveButton ] = useState(true);
    const [ showModal, setShowModal ] = useState(false);
    const [ menuClosed, setMenuClosed ] = useState({
        height: '3rem'
    });
    const [ menuOpened, setMenuOpened ] = useState({
        height: '6rem'
    });
    const [ menuStyle, setMenuStyle ] = useState(menuClosed);
    const [ iconPaddingRight, setIconPaddingRight ] = useState();

    // STYLES   
    const fadeStyle = {
        bottom: inactiveButton ? '-10rem' : '3rem',
        opacity: inactiveButton ? '0' : '1'
    }

    // Show Scroll To Top Button if window is scrolled
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setInactiveButton(false);
        }); 

        if(!inactiveButton) {
            setTimeout( () => {
                setInactiveButton(true);
            }, 3000);
        }
    }, [inactiveButton])

    // set menustyle on toggleMenu state change
    useEffect(() => {
        if(context.toggleMenu) {
            setMenuStyle(menuOpened);
        } else {
            setMenuStyle(menuClosed);
        }   
        
        IconPadding();
    // remove warning message:
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.toggleMenu])

    useEffect( () => {
        if(context.screenSize === 'desktop') {
            setMenuOpened({
                height: '6rem'
            });
            setMenuClosed({
                height: '3rem'
            });
        } else if(context.screenSize === 'tablet') {
            setMenuOpened({
                height: '4rem'
            });
            setMenuClosed({
                height: '2rem'
            });
        }

        IconPadding();
    }, [context.screenSize]);

    useEffect( () => {
        if(context.toggleMenu) {
            setMenuStyle(menuOpened);
        } else {
            setMenuStyle(menuClosed);
        }    
    }, [menuClosed]);

    // function to set padding Right based on screenSize state
    const IconPadding = () => {
        if(context.screenSize === 'desktop') {
            setIconPaddingRight({
                paddingRight: context.toggleMenu ? '6rem' : '3rem'
            });
        } else if(context.screenSize === 'tablet') {
            setIconPaddingRight({
                paddingRight: context.toggleMenu ? '4rem' : '2rem'
            });
        } else if(context.screenSize === 'phone') {
            setIconPaddingRight({
                paddingRight:'0.5rem'
            });
        }
    }
    
    return(
        <div>
            <Spring 
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
                config={{ delay: 0, duration: 1000}}
            >
                { props => (
                    <div style={ props }>
                        <button 
                            className='scroll-to-top-button' 
                            onClick={ () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                            style={ fadeStyle }
                        >
                            <i className="fas fa-chevron-up"></i>
                        </button>

                        <div className='top-margin' style={ menuStyle }>
                            {/* contact links */}
                            <div className='links' style={ iconPaddingRight } >
                                <ul>
                                    {/* <li>Contact: 647-990-1277</li>
                                    <li>Email: rickingyn@gmail.com</li> */}
                                    <li>
                                        <h5>
                                            <a href='http://www.linkedin.com/in/rickingyn' target='_blank' rel='noopener noreferrer'><i className="fab fa-linkedin-in"></i></a>
                                            <a href='https://github.com/rickingyn' target='_blank' rel='noopener noreferrer'><i className="fab fa-github"></i></a>
                                            <button 
                                                variant='primary' 
                                                onClick={ () => setShowModal(true) }
                                            >
                                                <i className="fas fa-file-alt"></i>
                                            </button>
                                            <Resume show={ showModal } onHide={ () =>  setShowModal(false) } />
                                        </h5>
                                    </li>
                                </ul>     
                            </div>                   
                        </div>
                        <div className='bottom-margin' style={ menuStyle }></div>

                        <div className={ context.isHomePage === false ? 'title-about hide' : 'title-home' }>
                            <p>HELLO<span>.</span></p>
                        </div>
                        
                        {/* sections */}
                        <div>
                            <div id='home'>
                                <Home />    
                            </div>          

                            <Menu>
                                <div id='projects'>
                                    <About />
                                </div>
                                <div id='project'>
                                    <Projects />
                                </div>
                                <div id='contact'>
                                    <Contact />
                                </div>
                            </Menu>
                        </div>

                    </div>
                )}
            </Spring>                     
        </div>
    );
}

export default Landing;