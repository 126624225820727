import React, { useEffect, useState } from "react";
// 3rd party imports
import axios from "axios"; // library for fetching api
import { useForm } from "react-hook-form"; // library for form validation
import { CopyToClipboard } from "react-copy-to-clipboard"; // library to copy to clipboard
import Alert from "react-bootstrap/Alert";
// local imports
import "../App.css";

const Contact = () => {
  const [contactMessage, setContactMessage] = useState({
    name: "",
    email: "",
    message: "",
    sending: false,
  });
  const [copy, setCopy] = useState({
    value: "",
    copied: false,
  });
  const [alert, setAlert] = useState({
    show: false,
    type: "",
  });

  const showAlert = {
    top: alert.show ? "3.5rem" : "-3.5rem",
  };

  // variable for validation from useForm library
  const { handleSubmit, errors, register } = useForm();

  // if copy state is changed to true, set to false after 2 seconds
  useEffect(() => {
    if (copy.copied) {
      setTimeout(() => {
        setCopy({ value: "", copied: false });
      }, 2000);
    }
  }, [copy.copied]);

  // if alert state is true, set to false after 3.5 seconds
  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        setAlert(false);
      }, 3500);
    }
  }, [alert.show]);

  // update value of form to value in input field
  const handleUpdate = (event) => {
    const { name, value } = event.target;

    setContactMessage({
      ...contactMessage,
      [name]: value,
    });
  };

  // on submit, make post request to email API and set input fields to empty
  const onSubmit = async (data) => {
    try {
      setContactMessage({
        ...contactMessage,
        sending: true,
      });

      const response = await axios.post(
        "https://rickingyn-email-api.herokuapp.com/api/v1",
        data
      );

      setContactMessage({
        name: "",
        email: "",
        message: "",
        sending: false,
      });

      setAlert({
        show: true,
        type: "success",
      });
    } catch (error) {
      setAlert({
        show: true,
        type: "failed",
      });

      setContactMessage({
        ...contactMessage,
        sending: false,
      });

      console.log("An error occured while sending message: ", error);
    }
  };

  return (
    <div className="Contact">
      <div className="Contact-container">
        <div className="Contact-container-content">
          <form
            className="contact100-form validate-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <span className="Contact-form-title">
              <span className="Contact-header-title">Contact</span> Me
            </span>

            <div className="Contact-information">
              <div className="Contact-information-detail">
                <i className="fas fa-map-marker-alt"></i>
                <p>North York, ON</p>
              </div>
              <div className="Contact-information-detail">
                <CopyToClipboard
                  text="rickingyn@gmail.com"
                  onCopy={() => setCopy({ value: "email", copied: true })}
                >
                  <i className="fas fa-envelope Contact-information-click-to-copy"></i>
                </CopyToClipboard>
                <CopyToClipboard
                  text="rickingyn@gmail.com"
                  onCopy={() => setCopy({ value: "email", copied: true })}
                >
                  <p className="Contact-information-click-to-copy">
                    rickingyn@gmail.com
                  </p>
                </CopyToClipboard>
                <CopyToClipboard
                  text="rickingyn@gmail.com"
                  onCopy={() => setCopy({ value: "email", copied: true })}
                >
                  {copy.copied && copy.value === "email" ? (
                    <span className="Contact-information-click-to-copy-tooltip-copied">
                      Copied!
                    </span>
                  ) : (
                    <span className="Contact-information-click-to-copy-tooltip">
                      Copy
                    </span>
                  )}
                </CopyToClipboard>
              </div>
              <div className="Contact-information-detail">
                <CopyToClipboard
                  text="647-990-1277"
                  onCopy={() => setCopy({ value: "mobile", copied: true })}
                >
                  <i className="fas fa-mobile Contact-information-click-to-copy"></i>
                </CopyToClipboard>
                <CopyToClipboard
                  text="647-990-1277"
                  onCopy={() => setCopy({ value: "mobile", copied: true })}
                >
                  <p className="Contact-information-click-to-copy">
                    647-990-1277
                  </p>
                </CopyToClipboard>
                <CopyToClipboard
                  text="647-990-1277"
                  onCopy={() => setCopy({ value: "mobile", copied: true })}
                >
                  {copy && copy.value === "mobile" ? (
                    <span className="Contact-information-click-to-copy-tooltip-copied">
                      Copied!
                    </span>
                  ) : (
                    <span className="Contact-information-click-to-copy-tooltip">
                      Copy
                    </span>
                  )}
                </CopyToClipboard>
              </div>
            </div>

            <div className="Contact-form-section-1">
              <div className="Contact-form-section-1-input">
                <div
                  className="Contact-form-input-container"
                  data-validate="Name is required"
                >
                  <span className="Contact-form-label">Name</span>
                  <input
                    className="Contact-form-input"
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    ref={register({ required: true })}
                    value={contactMessage.name}
                    onChange={handleUpdate}
                  />
                  <span className="Contact-form-input-focus"></span>
                </div>
                <span className="form-error-message">
                  {errors.name && <p>This is required</p>}
                </span>
              </div>
              <div className="Contact-form-section-1-input">
                <div
                  className="Contact-form-input-container"
                  data-validate="Valid email is required: ex@abc.xyz"
                >
                  <span className="Contact-form-label">Email</span>
                  <input
                    className="Contact-form-input"
                    type="text"
                    name="email"
                    placeholder="Enter your email"
                    ref={register({ required: true })}
                    value={contactMessage.email}
                    onChange={handleUpdate}
                  />
                  <span className="Contact-form-input-focus"></span>
                </div>
                <span className="form-error-message">
                  {errors.email && <p>This is required</p>}
                </span>
              </div>
            </div>

            <div className="Contact-form-section-1-input">
              <div
                className="Contact-form-input-container"
                data-validate="Message is required"
              >
                <span className="Contact-form-label">Message</span>
                <textarea
                  className="Contact-form-input"
                  name="message"
                  placeholder="Enter a message..."
                  ref={register({ required: true })}
                  value={contactMessage.message}
                  onChange={handleUpdate}
                ></textarea>
                <span className="Contact-form-input-focus"></span>
              </div>
              <span className="form-error-message">
                {errors.message && <p>This is required</p>}
              </span>
            </div>

            <div className="container-contact100-form-btn">
              <button className="contact100-form-btn">
                <span>
                  {contactMessage.sending ? (
                    <span className="animate-sending">Sending</span>
                  ) : (
                    <span>
                      Send Message{" "}
                      <i
                        className="fa fa-long-arrow-right m-l-7"
                        aria-hidden="true"
                      ></i>
                    </span>
                  )}
                </span>
              </button>
            </div>
          </form>

          <Alert
            className="alert-sent"
            style={showAlert}
            variant={alert.type === "success" ? "success" : "danger"}
            onClose={() => setAlert({ show: false, type: "" })}
            dismissible
          >
            {alert.type === "failed" ? (
              <p>Sorry! An Error Has Occured!</p>
            ) : (
              <p>Message Sent!</p>
            )}
          </Alert>
        </div>
      </div>
    </div>
  );
};

export default Contact;
