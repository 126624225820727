import React, { useContext } from "react";
// 3rd party library imports
import { HashLink as Link } from "react-router-hash-link"; // library to scroll to position on page
import { Spring } from "react-spring/renderprops"; // library for fading animation
import { useScrollPosition } from "@n8tb1t/use-scroll-position"; // library to detect scroll position
// local imports
import { PageContext } from "./context";
import "../App.css";

const Home = () => {
  // subscribe to context api
  const context = useContext(PageContext);

  // hide show home page based on scroll position; hide if page is scrolled from top
  useScrollPosition(({ currPos }) => {
    if (currPos.y < 0) {
      context.action.setIsHomePage(false);
    } else if (currPos.y === 0) {
      context.action.setIsHomePage(true);
    }
  });

  return (
    // use Spring library to fade in component
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
      config={{ delay: 1000, duration: 1000 }}
    >
      {(props) => (
        <div style={props}>
          <div
            className="Home"
            style={{
              height: context.isHomePage === false ? "0" : "100vh",
              padding:
                context.isHomePage === false
                  ? "0"
                  : context.screenSize === "desktop"
                  ? "3rem"
                  : context.screenSize === "tablet"
                  ? "2rem"
                  : "0",
            }}
          >
            <div className="Home-left-container">
              {/* use Spring library to fade in text after component */}
              <Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
                config={{ duration: 1000 }}
              >
                {(props) => (
                  <div style={props}>
                    <div className="Home-left-container-heading">
                      <h1>Ricki Nguyen</h1>
                      <h5>Software Engineer</h5>
                    </div>

                    <ul className="Home-left-container-menu">
                      <div className="Home-left-container-menu-list-container">
                        <li className="Home-left-container-menu-list">
                          <Link
                            className="Home-left-container-menu-list-item"
                            to="/about#about"
                            scroll={() =>
                              window.scrollTo({
                                top: 100,
                                left: 0,
                                behavior: "smooth",
                              })
                            }
                          >
                            About
                          </Link>
                        </li>
                        <li className="Home-left-container-menu-list">
                          <Link
                            className="Home-left-container-menu-list-item"
                            to="/projects#projects"
                            scroll={(el) =>
                              el.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                                inline: "nearest",
                              })
                            }
                          >
                            Projects
                          </Link>
                        </li>
                        <li className="Home-left-container-menu-list">
                          <Link
                            className="Home-left-container-menu-list-item"
                            to="/contact#contact"
                            scroll={(el) =>
                              el.scrollIntoView({
                                behavior: "smooth",
                                block: "end",
                                inline: "end",
                              })
                            }
                          >
                            Contact
                          </Link>
                        </li>
                      </div>
                      <div className="Home-left-container-menu-list-arrow">
                        <i className="fas fa-arrow-alt-circle-down"></i>
                      </div>
                    </ul>
                  </div>
                )}
              </Spring>
            </div>

            <div className="Home-right-container">
              <div
                className="Home-right-container-description"
                style={{ opacity: context.isHomePage === false ? "0" : "1" }}
              >
                <h5>
                  React JS | Angular JS | Node/Express JS | JavaScript | TypeScript | MongoDB | MySQL | Lambda Webhooks |
                  HTML/CSS{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
      )}
    </Spring>
  );
};

export default Home;
