import React from "react";
// import bootstrap
import { Button, Modal } from "react-bootstrap";
// local imports
import "../App.css";

const Project = (props) => {
  let descriptionArray = props.project.description.split("\n");

  return (
    <div className="Project">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>Project Information</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Project-body">
            <h4>{props.project.project_name}</h4>
            <h6>
              <strong>{props.project.course}</strong>
            </h6>
            {/* <p>{props.project.description.split("\n").join()}</p> */}
            {descriptionArray.map((desc, index) => {
              return (
                <p
                  key={index}
                  style={{ fontWeight: desc.includes("test") && "bold" }}
                >
                  {desc}
                </p>
              );
            })}
            <br />
            <p>
              <strong>Technologies:</strong>
            </p>
            <ul>
              {props.project.technologies.map((technology, index) => (
                <li key={index}>{technology}</li>
              ))}
            </ul>

            <img src={props.project.image_urls[1]} alt="project" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Close</Button> */}
          <a
            href={props.project.live_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="Project-demo-button" onClick={props.onHide}>
              Live Demo
            </Button>
          </a>
          <a
            href={props.project.github_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.project.github_link && (
              <Button className="Project-repo-button" onClick={props.onHide}>
                GitHub Repo
              </Button>
            )}
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Project;
