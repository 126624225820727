import React, { useContext, useEffect, useRef, useState } from "react";
// 3rd party library imports
import { HashLink as Link } from "react-router-hash-link"; // library to navigate to different position on page
// local import
import { PageContext } from "./context";

const Menu = (props) => {
  // subscribe to Context API
  const context = useContext(PageContext);

  // create reference point for menul panel; use to close menu if clicked outside of menu panel when open
  const node = useRef();

  const [menuClosed, setMenuClosed] = useState({
    margin: "3rem",
    width: "width: calc(100vw - 6rem)",
  });
  const [menuOpened, setMenuOpened] = useState({
    margin: "6rem",
    width: "width: calc(100vw - 12rem)",
  });
  const [menuStyle, setMenuStyle] = useState(menuClosed);

  // STYLES
  const menuPanelStyle = {
    zIndex: context.toggleMenu ? "34" : "0",
    opacity: context.toggleMenu ? "1" : "0",
    left: context.toggleMenu ? "0" : "-30rem",
    width: context.toggleMenu ? "80vw" : "0",
  };
  const menuListStyle = {
    opacity: context.toggleMenu ? "1" : "0",
    left: context.toggleMenu ? "0" : "-30rem",
    width: context.toggleMenu ? "100%" : "0",
  };

  // set menustyle on toggleMenu state change
  useEffect(() => {
    if (context.toggleMenu) {
      setMenuStyle(menuOpened);
    } else {
      setMenuStyle(menuClosed);
    }
    // remove warning message:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.toggleMenu]);

  useEffect(() => {
    if (context.screenSize === "desktop") {
      setMenuOpened({
        margin: "6rem",
        width: "width: calc(100vw - 12rem)",
      });
      setMenuClosed({
        margin: "3rem",
        width: "width: calc(100vw - 6rem)",
      });
    } else if (context.screenSize === "tablet") {
      setMenuOpened({
        margin: "4rem",
        width: "width: calc(100vw - 8rem)",
      });
      setMenuClosed({
        margin: "2rem",
        width: "width: calc(100vw - 4rem)",
      });
    }
  }, [context.screenSize]);

  useEffect(() => {
    if (context.toggleMenu) {
      setMenuStyle(menuOpened);
    } else {
      setMenuStyle(menuClosed);
    }
  }, [menuClosed]);

  // close menu panel on toggleMenu state change if clicked outside of reference
  useEffect(() => {
    if (context.toggleMenu) {
      document.addEventListener("mousedown", (event) => {
        if (!node.current.contains(event.target)) {
          handleClick();
        }
      });
    }
    // remove warning message:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.toggleMenu]);

  // Toggle menu; action subscribed from context
  const handleClick = () => {
    context.action.setToggleMenu(!context.toggleMenu);
  };

  return (
    <div className="Menu" style={menuStyle}>
      <div ref={node}>
        <button
          className={
            context.toggleMenu
              ? "Menu-button-selected"
              : context.isHomePage === true
              ? "Menu-button hide-left"
              : "Menu-button"
          }
          onClick={handleClick}
        >
          <p>menu</p>
          <div className="Menu-button-border"></div>
        </button>

        {/* inline style - use menuPanelStyle object for displaying menu */}
        <div className="Menu-panel" style={menuPanelStyle}>
          <ul style={menuListStyle}>
            <li>
              <Link
                className="Menu-list-item"
                to="/#home"
                scroll={() => {
                  context.action.setToggleMenu(false);
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="Menu-list-item"
                to="/about#about"
                scroll={() => {
                  context.action.setToggleMenu(false);
                  window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
                }}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                className="Menu-list-item"
                to="/projects#project"
                scroll={(el) => {
                  context.action.setToggleMenu(false);
                  el.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "end",
                  });
                }}
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                className="Menu-list-item"
                to="/contact#contact"
                scroll={(el) => {
                  context.action.setToggleMenu(false);
                  el.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "end",
                  });
                }}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default Menu;
