import React, { useEffect, useState } from "react";
// Aos - animation
import Aos from "aos";
import "aos/dist/aos.css";
// local imports
import Project from "./Project";
import projects from "../data.json";
import "../App.css";

const Projects = () => {
  const [imageHover, setImageHover] = useState();
  const [showModal, setShowModal] = useState(false);
  const [projectNum, setProjectNum] = useState();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="Projects">
      <div className="Projects-text-container">
        <div className="Projects-header">
          <h1>
            <span>Projects</span>
          </h1>
        </div>
      </div>

      <div className="Projects-image-container">
        {projects.projects
          .map((project, index) => {
            return (
              <div className="Projects-image-card" key={index}>
                {/* 
                                    animate gif on hover;
                                    on enter, set imageHover state to image url
                                    if imageHover is not empty, check if imageHover contains image url
                                        if it includes, show gif; else show image
                                    on leave, set imageHover back to false
                                    */}

                <div className="Projects-image-card-image">
                  <img
                    src={process.env.PUBLIC_URL + project.image_urls[0]}
                    onMouseOver={(event) => {
                      setImageHover(event.target.src);
                    }}
                    alt="project"
                  />

                  <button
                    onMouseLeave={() => {
                      setImageHover(false);
                    }}
                    className="Projects-image-card-detail"
                    variant="primary"
                    onClick={() => {
                      setShowModal(true);
                      setProjectNum(index);
                    }}
                  >
                    <h4>{project.project_name}</h4>
                    <h6>View</h6>
                    <i className="fas fa-info-circle"></i>
                  </button>
                </div>
                <Project
                  show={showModal && projectNum === index}
                  onHide={() => setShowModal(false)}
                  project={project}
                />
              </div>
            );
          })
          .reverse()}
      </div>
    </div>
  );
};

export default Projects;
