import React, { useEffect } from "react";
import Parallax from "react-rellax";
// Local imports
import ProfilePicture from "../local/about-pic.jpg";
import "../App.css";

import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id="about" className="About">
      <Parallax speed={-2}>
        <div className="About-text-container" data-aos="fade-up">
          <h1>
            Hello, I'm <span>Ricki</span>
          </h1>
          <br />
          <h6>
            Whether I'm on the computer spending hours engulfed in writing codes
            or on the basketball court practicing my shots, I always aim for
            perfection and improvements. As a developer, I take the same
            approach with my clients, striving towards building the best
            solutions.
            <br />
            <br />
            When I'm not coding, you can find me renovating my home, researching
            the latest cryptocurrency news or strumming a top 40s song on my
            guitar.
          </h6>
          <br />
          <br />
          <div className="About-text-container-image">
            <img src={ProfilePicture} alt="Profile pic" />
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default About;
