import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ResumeFile from '../local/ricki_nguyen_resume.pdf';

const Resume = (props) => {
    const [ pdfHeight, setPdfHeight ] = useState(`calc(${window.innerHeight}-100)px`);
    
    useEffect( () => {
        const defaultHeight = window.innerHeight - 100;
        
        setPdfHeight(`${defaultHeight}px`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect( () => {
        window.addEventListener('resize', () => {
            if(window.innerHeight > '1050') {
                setPdfHeight('1000px');
            } else if( window.innerHeight <= '1050' && window.innerHeight > '850') {
                setPdfHeight('800px');
            } else if(window.innerHeight <= '850' && window.innerHeight > '550') {
                setPdfHeight('500px');
            }
        });
    }, [pdfHeight]);

    return(
        <div className='Resume'>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                    <embed src={ ResumeFile } width='100%' height={ pdfHeight } alt='Ricki Resume' />
            </Modal>
        </div>
    )
}

export default Resume;