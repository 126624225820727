import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// local imports
import { PageContext } from './context';
import Loading from './Loading';
import Landing from './Landing';

const Routers = () => {
    const context = useContext(PageContext);

    return(
        <React.Fragment>
            {/* render loading component  */}
            { context.loading ? <Loading /> : (
                <React.Fragment>
                    <Switch>
                        <Route exact path='/' component={ Landing } />
                        <Route exact path='/about' component={ Landing } />
                        <Route exact path='/projects' component={ Landing } />
                        <Route exact path='/contact' component={ Landing } />
                        <Redirect to='/' />
                    </Switch>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default Routers;